import $ from "jquery";

/*
 * 特定のスクロール位置でcallbackを実行
 * - callback: 完了後に実行する関数
 * - namespace: 名前空間
 * - elm: 対象要素
 * - adjust: 発火位置調整用
 */
export default class InView {
  constructor(callback, namespace, elm, adjust) {
    this.callback = callback;
    this.namespace = namespace;
    this.elm = elm;
    this.adjust = adjust;
    this.init();
  }

  init() {
    if (typeof this.callback == "function") {
      const self = this;
      $(window).on("scroll" + "." + this.namespace, function() {
        const that = this;
        const gpos = $(that).scrollTop();
        const elempos = $(self.elm).offset();
        const adjustVal = self.adjust ? self.adjust : 0;
        if (gpos >= elempos.top - $(window).height() + adjustVal) {
          self.callback(self.elm);
          $(that).off("." + self.namespace);
        }
      });
    }
  }
}
