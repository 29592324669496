import InView from "../lib/InView";

export default class Header {
  constructor(selector) {
    this.list = document.getElementsByClassName(selector);
    this.listNode = Array.prototype.slice.call(this.list, 0);
    this.init();
  }

  init() {
    this.bindEvents();
  }

  bindEvents() {
    const afterInView = elm => {
      elm.classList.add("-show");
    };
    this.listNode.forEach((elm, index) => {
      const namespace = "inView" + index;
      // InView イベント追加
      new InView(afterInView, namespace, elm, 180);
    });
  }
}
