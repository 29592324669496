/*
 * ユーザーエージェントを判定
 * sp - スマートフォン
 * tab - タブレット
 * pc - PC
 */

export default class UserAgent {
  constructor() {}

  check() {
    const ua = navigator.userAgent;
    if (
      ua.indexOf("iPhone") > 0 ||
      (ua.indexOf("Android") > 0 && ua.indexOf("Mobile") > 0)
    ) {
      return "sp";
    } else if (ua.indexOf("iPad") > 0 || ua.indexOf("Android") > 0) {
      return "tab";
    } else {
      return "pc";
    }
  }
}
