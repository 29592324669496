import $ from "jquery";

import KeyVisual from "./KeyVisual";

$(() => {
  // KeyVisual Slider
  if (document.getElementsByClassName("js-kvSliderTop").length) {
    new KeyVisual("js-kvSliderTop");
  }
});
