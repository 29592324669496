import $ from "jquery";

import Header from "./Header";
import HeaderSp from "./HeaderSp";
import PageTop from "./PageTop";
import ScrollView from "./ScrollView";
import SideBanner from "./SideBanner";

$(() => {
  if (document.getElementsByClassName("js-header").length) {
    new Header("js-header");
    if (document.getElementsByClassName("js-spMenuBtn").length) {
      new HeaderSp("js-header");
    }
  }

  if (document.getElementsByClassName("js-pageTop").length) {
    new PageTop("js-pageTop");
  }

  if (document.getElementsByClassName("js-scrollView").length) {
    new ScrollView("js-scrollView");
  }

  if (document.getElementsByClassName("js-sideBanner").length) {
    new SideBanner("js-sideBanner");
  }
});
