import _ from "underscore";
import UserAgent from "../lib/UserAgent";

export default class Header {
  constructor(selector) {
    this.elm = document.getElementsByClassName(selector)[0];
    this.navList = this.elm.getElementsByClassName("headerMenu__list");
    this.navNode = Array.prototype.slice.call(this.navList, 0);
    this.breakPoint = 769;
    this.ua = new UserAgent();
    this.init();
  }

  init() {
    this.bindEvents();

    if (window.innerWidth >= this.breakPoint) {
      _.delay(() => {
        this.setListSize();
      }, 1000);
    }
  }

  bindEvents() {
    const self = this;

    // menu event
    this.navNode.forEach(elm => {
      // menu link 無効
      const link = elm
        .getElementsByClassName("headerMenu__nav")[0]
        .getElementsByTagName("a")[0];
      link.addEventListener("click", event => {
        event.preventDefault();
      });

      // mouseenter (pc)
      link.addEventListener("mouseenter", () => {
        if (this.ua.check() == "pc") {
          self.open(elm);
        }
      });

      // mouseleave (pc)
      elm.addEventListener("mouseleave", () => {
        if (this.ua.check() == "pc") {
          self.close(elm);
        }
      });

      // click event (sp, tab);
      link.addEventListener(
        "click",
        _.debounce(() => {
          if (this.ua.check() != "pc") {
            if (elm.classList.contains("is-open")) {
              self.close(elm);
            } else {
              self.closeAll();
              self.open(elm);
            }
          }
        }, 60)
      );
    });

    // resize event
    this.debouncedOnResize = _.debounce(() => {
      this.onResize();
    }, 600);
    window.addEventListener("resize", this.debouncedOnResize);

    // font size btn
    const fontSize = document.getElementById("fontSize");
    if (fontSize) {
      // 文字サイズ変更を監視
      const observer = new MutationObserver(
        _.debounce(() => {
          self.resetListSize();
          self.setListSize();
        }, 1000)
      );
      observer.observe(fontSize, {
        attributes: true,
        childList: true,
        subtree: true
      });
    }
  }

  open(elm) {
    if (window.innerWidth < this.breakPoint) return;
    elm.classList.add("is-open");
  }

  close(elm) {
    if (window.innerWidth < this.breakPoint) return;
    elm.classList.remove("is-open");
  }

  closeAll() {
    this.navNode.forEach(elm => {
      elm.classList.remove("is-open");
    });
  }

  // ナビ内の横幅、高さを調整
  setListSize() {
    this.navNode.forEach(elm => {
      let content = elm.getElementsByClassName("headerMenu__content")[0];
      let linkList = content.getElementsByTagName("ul");
      let linkListNode = Array.prototype.slice.call(linkList, 0);
      let maxWidth = elm.clientWidth;
      let maxWidthLeft = 0;
      let maxWidthRight = 0;
      let maxHeightArr = [];
      let col = 1;
      if (content.classList.contains("headerMenu__content--col2")) {
        col = 2;
      }

      linkListNode.forEach(elm => {
        if (col == 1) {
          if (elm.clientWidth > maxWidth) {
            maxWidth = elm.clientWidth;
          }
        } else if (col == 2) {
          const list = elm.getElementsByTagName("li");
          const listNode = Array.prototype.slice.call(list, 0);
          let maxHeight = 0;
          listNode.forEach((elm, index) => {
            if (!(index % 2)) {
              if (!index == 0) {
                maxHeightArr.push(maxHeight);
              }
              maxHeight = 0;
              if (maxWidthLeft < elm.clientWidth) {
                maxWidthLeft = elm.clientWidth;
              }
            } else {
              if (maxWidthRight < elm.clientWidth) {
                maxWidthRight = elm.clientWidth;
              }
            }
            if (maxHeight < elm.clientHeight) {
              maxHeight = elm.clientHeight;
            }
          });
          maxHeightArr.push(maxHeight);
        }
      });

      if (col == 1) {
        linkListNode.forEach(elm => {
          elm.style.width = maxWidth + "px";
        });
      } else if (col == 2) {
        linkListNode.forEach(elm => {
          const list = elm.getElementsByTagName("li");
          const listNode = Array.prototype.slice.call(list, 0);
          let listCount = 0;

          elm.style.width = maxWidthLeft + maxWidthRight + "px";

          listNode.forEach((elm, index) => {
            const link = elm.getElementsByTagName("a")[0];
            if (!(index % 2)) {
              elm.style.width = maxWidthLeft + "px";
              if (index != 0) {
                listCount++;
              }
            } else {
              elm.style.width = maxWidthRight + "px";
            }
            link.style.height = maxHeightArr[listCount] + "px";
          });
        });
      }
    });
  }

  resetListSize() {
    this.navNode.forEach(elm => {
      let content = elm.getElementsByClassName("headerMenu__content")[0];
      let linkList = content.getElementsByTagName("ul");
      let linkListNode = Array.prototype.slice.call(linkList, 0);

      linkListNode.forEach(elm => {
        elm.style.width = "";
        if (content.classList.contains("headerMenu__content--col2")) {
          const list = elm.getElementsByTagName("li");
          const listNode = Array.prototype.slice.call(list, 0);
          listNode.forEach(elm => {
            elm.style.width = "";
            elm.getElementsByTagName("a")[0].style.height = "";
          });
        }
      });
    });
  }

  onResize() {
    this.resetListSize();
    if (window.innerWidth < this.breakPoint) {
      this.closeAll();
    } else {
      this.setListSize();
    }
  }
}
