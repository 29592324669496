import _ from "underscore";

export default class HeaderSp {
  constructor(selector) {
    this.elm = document.getElementsByClassName(selector)[0];
    this.platform = document.getElementById("platform");
    this.menuBtn = this.elm.getElementsByClassName("js-spMenuBtn")[0];
    this.content = this.elm.getElementsByClassName("header__content")[0];
    this.header = this.elm.getElementsByClassName("header__logo")[0];
    this.inner = this.elm.getElementsByClassName("header__contentInner")[0];
    this.navList = this.elm.getElementsByClassName("headerMenu__nav");
    this.navNode = Array.prototype.slice.call(this.navList, 0);
    this.ww = window.innerWidth;
    this.breakPoint = 769;
    this.openFlg = false;
    this.lockFlg = false;
    this.scrollTop = 0;
    this.init();
  }

  init() {
    this.bindEvents();
  }

  bindEvents() {
    const self = this;

    // ハンバーガーメニュー
    this.menuBtn.addEventListener("click", event => {
      event.preventDefault();
    });
    this.debouncedOnMenuClick = _.debounce(() => {
      self.menu();
    }, 60);
    this.menuBtn.addEventListener("click", this.debouncedOnMenuClick);

    // アコーディオンメニュー
    this.debouncedOnNavClick = _.debounce(event => {
      self.nav(event.target);
    }, 60);
    this.navNode.forEach(elm => {
      const link = elm.getElementsByTagName("a")[0];
      link.addEventListener("click", event => {
        event.preventDefault();
      });
      link.addEventListener("click", this.debouncedOnNavClick);
    });

    // リサイズイベント追加
    this.debouncedOnResize = _.debounce(() => {
      this.onResize();
    }, 600);
    window.addEventListener("resize", this.debouncedOnResize);
  }

  // menu click event
  menu() {
    if (this.lockFlg) return;
    this.lockFlg = true;
    _.delay(() => {
      this.lockFlg = false;
    }, 1000);

    if (this.menuBtn.classList.contains("is-active")) {
      this.close();
    } else {
      this.open();
    }
  }

  // set platform fixed
  setFixed() {
    this.scrollTop = window.pageYOffset;
    this.platform.style.width = "100%";
    this.platform.style.top = -1 * this.scrollTop + "px";
  }

  // reset platform fixed
  resetFixed() {
    this.platform.style.width = "";
    this.platform.style.top = "";
  }

  // menu set height
  setHeight() {
    this.inner.style.height =
      window.innerHeight - this.header.clientHeight + "px";
  }

  // menu reset height
  resetHeight() {
    this.inner.style.height = "";
  }

  // menu open
  open() {
    const self = this;
    this.menuBtn.classList.add("is-active");
    this.content.classList.add("is-active");
    this.openFlg = true;
    this.setFixed();
    _.delay(() => {
      self.setHeight();
      self.content.classList.add("-show");
    }, 100);
  }

  // menu close
  close() {
    const self = this;
    this.menuBtn.classList.remove("is-active");
    this.content.classList.remove("-show");
    this.resetFixed();
    window.scrollTo(0, this.scrollTop);
    this.scrollTop = 0;
    _.delay(() => {
      self.content.classList.remove("is-active");
      _.delay(() => {
        self.resetHeight();
        self.openFlg = false;
      }, 100);
    }, 600);
  }

  // nav menu click event
  nav(elm) {
    if (!this.openFlg) return;
    const list = elm.parentNode.parentNode;
    if (list.classList.contains("is-active")) {
      this.navClose(list);
    } else {
      this.navOpen(list);
    }
  }

  // nav menu open
  navOpen(elm) {
    elm.classList.add("is-active");
  }

  // nav menu close
  navClose(elm) {
    elm.classList.remove("is-active");
  }

  // リサイズイベント
  onResize() {
    if (this.openFlg) {
      this.resetHeight();
      if (window.innerWidth >= this.breakPoint) {
        this.close();
        this.resetFixed();
        this.lockFlg = false;
      } else {
        this.setHeight();
      }
    }
  }
}
