import $ from "jquery";
import "slick-carousel";

export default class KeyVisual {
  constructor(selector) {
    this.elm = document.getElementsByClassName(selector)[0];
    this.target = this.elm.getElementsByClassName("keyVisualTop__image")[0];
    this.prevBtn = this.elm.getElementsByClassName("js-prevBtn")[0];
    this.nextBtn = this.elm.getElementsByClassName("js-nextBtn")[0];
    this.pickUp = document.getElementsByClassName("pickupTop")[0];
    this.init();
  }

  init() {
    const self = this;

    $(this.target).on("init", function(event, slick) {
      self.pickUp.classList.add("-show");
    });

    const slider = $(this.target).slick({
      lazyLoad: "progressive",
      autoplay: false,
      infinite: true,
      dots: false,
      prevArrow: false,
      nextArrow: false,
      fade: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1
    });

    // prev btn
    const sliderPrev = e => {
      e.preventDefault();
      slider.slick("slickPrev");
    };
    this.prevBtn.addEventListener("click", sliderPrev, false);

    // next btn
    const sliderNext = e => {
      e.preventDefault();
      slider.slick("slickNext");
    };
    this.nextBtn.addEventListener("click", sliderNext, false);
  }
}
