import _ from "underscore";
import IsPassiveSupport from "../lib/IsPassiveSupport";

export default class SideBanner {
  constructor(selector) {
    this.elm = document.getElementsByClassName(selector)[0];
    this.timeout;
    this.delayTimeout;
    this.init();
  }

  init() {
    this.bindEvents();
  }

  bindEvents() {
    const isPassive = new IsPassiveSupport();
    this.onScroll = () => {
      if (this.timeout) {
        cancelAnimationFrame(this.timeout);
        clearTimeout(this.delayTimeout);
      }
      this.timeout = requestAnimationFrame(() => {
        let y = window.pageYOffset;
        let startPos = 640;
        if (y > startPos) {
          this.elm.classList.remove("-hide");
          this.delayTimeout = _.delay(() => {
            this.elm.classList.add("-show");
          }, 1);
        } else {
          this.elm.classList.remove("-show");
          this.delayTimeout = _.delay(() => {
            this.elm.classList.add("-hide");
          }, 1000);
        }
      });
    }
    window.addEventListener(
      "scroll",
      this.onScroll,
      isPassive.check() ? { passive: true } : false
    );
  }
}
