import $ from "jquery";
import _ from "underscore";

export default class PageTop {
  constructor(selector) {
    this.elm = document.getElementsByClassName(selector)[0];
    this.init();
  }

  init() {
    this.bindEvents();
  }

  bindEvents() {
    const self = this;

    // click event;
    this.elm.addEventListener("click", event => {
      event.preventDefault();
    });
    this.debouncedOnClick = _.debounce(() => {
      self.click();
    }, 60);
    this.elm.addEventListener("click", this.debouncedOnClick);
  }

  click() {
    $("body, html").animate({ scrollTop: 0 }, 500);
  }
}
